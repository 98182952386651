import { useState, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { Button, Table, Badge, Popover, PopoverBody, PopoverHeader, UncontrolledTooltip } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPaperPlane,
  faWrench,
  faTrashAlt,
  faUser,
  faStethoscope,
  faCircleCheck
} from "@fortawesome/free-solid-svg-icons"
import moment from "moment"
import API from "API"
import parseSerialNumber from "utils/serialNumberParser"

import RepairStats from "components/RepairsStats"
import Tooltip from "components/Tooltip"

import { ActionTypes } from "context/scannerProvider"
import { d } from "utils/dispatchHelper"
import {
  repairsStatuses,
  mapStatusToColor,
  mapStatusToName,
  createdShortDateFormat,
  repairPersonel
} from "constants/repairs"

import useScannerContext from "hooks/useScannerContext"
import useStickyState from "hooks/useStickyState"

import "./style.scss"

const BadgeStyle = {
  fontSize: "11pt"
}

const RepairsList = ({ onFetchCallback, setEditRepairId }) => {
  const [repairsList, setRepairsList] = useState([])
  const [showDoneRepairs, setShowDoneRepairs] = useStickyState(true, "show-done-repairs")
  const [state, dispatch] = useScannerContext()
  const [dropdownList, setDropdownList] = useState({})
  const toggleDoneCallback = () => setShowDoneRepairs(!showDoneRepairs)

  const handleRepairEditCallback = useCallback(
    async event => {
      event.preventDefault()
      const { repairid } = event.target.dataset

      if (repairid !== undefined) {
        setEditRepairId(repairid)
      }
      // const row = state.repairs.find(repair => repair.id === repairid)

      // if (row !== undefined) {
      // console.log("found row", row)
      // }
    },
    [setEditRepairId]
  )

  const handleRepairUpdateCallback = useCallback(async event => {
    event.preventDefault()

    const { repairid, updatedstatus } = event.target.dataset

    const disableButton = (disabled = false) => {
      event.target.disabled = disabled
    }

    if (repairid && updatedstatus) {
      disableButton(true)
      await API.updateRepair(repairid, updatedstatus, null, null, disableButton(false))
    }
  }, [])

  const handleDiagnoseOrStartRepair = useCallback(
    async event => {
      event.preventDefault()

      const { repairid, updatedstatus } = event.target.dataset

      if (repairid) {
        // const currentRepairData = state.repairs.find(repair => repair.id === repairid) // Find repair data by ID

        dispatch({
          type: ActionTypes.TOGGLE_REPAIR_PERSONEL_MODAL,
          payload: { id: repairid, nextState: updatedstatus } // Pass repair data to the modal
        })
      }
    },
    [dispatch]
  )

  const calculateDurations = (status, updatedAt = null, doneAt = null) => {
    switch (status) {
      case repairsStatuses.IN_PROGRESS: {
        const diff = moment.unix(updatedAt.seconds).diff(moment())
        const durration = moment.duration(diff)
        return durration.humanize()
      }
      case repairsStatuses.DONE:
      case repairsStatuses.SENT:
      case repairsStatuses.WAITING_FOR_PAYMENT: {
        const dateDiff = moment.unix(updatedAt.seconds).diff(moment.unix(doneAt.seconds))
        const durr = moment.duration(dateDiff)
        return durr.humanize()
      }

      default:
        return ""
    }
  }

  const mapRepairToAction = useCallback(
    row => {
      const { id, createdAt = null, updatedAt = null, doneAt = null, serial, status, ...others } = row
      // const dateCreated = moment.unix(createdAt.seconds).format(repairsCreatedAtDateFormat)
      let timeSpent = status !== repairsStatuses.NEW ? calculateDurations(status, updatedAt, doneAt) : ""
      const rapairStartedDate = moment.unix(createdAt.seconds).format(createdShortDateFormat)
      const linkId = "R" + id.replace("R", "")
      const { productModel = false } = parseSerialNumber(serial)

      console.log("others", others, status)

      const RepairIdLink = () =>
        [repairsStatuses.SENT].includes(status) ? (
          <span>{linkId}</span>
        ) : (
          <span role="button" data-repairid={id} onClick={handleRepairEditCallback}>
            {linkId}
          </span>
        )

      setDropdownList({
        ...dropdownList,
        [id]: false
      })

      return (
        /* mark orange when repair is NEW for at least 2 weeks */
        <tr
          key={id}
          className={`${
            status === repairsStatuses.NEW && moment().diff(moment.unix(createdAt.seconds), "days") > 14
              ? "late-repair"
              : ""
          }`}
        >
          {/* <tr key={id}> */}
          <td className="repairLink">
            <RepairIdLink />
          </td>
          <td className="repair-serial">
            {serial} <span>{productModel ? `(${productModel.title})` : ""}</span>
          </td>
          <td className="repair-dates">
            {/* <span > */}
            {timeSpent}
            <span>utworzono: {rapairStartedDate}</span>
            {/* </span> */}
          </td>
          <td>
            <Badge color={mapStatusToColor(status)} style={BadgeStyle}>
              {mapStatusToName(status)}
            </Badge>
            {/* display days when repair is waiting when NEW */}
            {status === repairsStatuses.NEW && (
              <span> {moment().diff(moment.unix(createdAt.seconds), "days")} dni</span>
            )}
          </td>
          <td className="repair-person">
            {status === repairsStatuses.IN_PROGRESS && others?.repairPerson && (
              <>
                <Tooltip text={`Diagnoza: ${repairPersonel[others?.diagnosePerson]}`}>
                  <span id={`repair-person-repair-id-${id}`}>
                    <FontAwesomeIcon icon={faWrench} /> {repairPersonel[others?.repairPerson]}
                  </span>
                </Tooltip>
              </>
            )}
            {status === repairsStatuses.DIAGNOSIS && others?.diagnosePerson && (
              <>
                <span id={`repair-person-diagnosis-id-${id}`}>
                  <FontAwesomeIcon icon={faStethoscope} /> {repairPersonel[others?.diagnosePerson]}
                </span>
              </>
            )}
          </td>
          <td className="row-actions">
            {status === repairsStatuses.NEW && (
              <Button
                color="diagnosis"
                size="sm"
                onClick={handleDiagnoseOrStartRepair}
                data-repairid={id}
                data-updatedstatus={repairsStatuses.DIAGNOSIS}
              >
                <FontAwesomeIcon icon={faStethoscope} /> Rozpocznij diagnoze
              </Button>
            )}
            {status === repairsStatuses.DIAGNOSIS && (
              <Button
                color="ready-for-repair"
                size="sm"
                onClick={handleRepairUpdateCallback}
                data-repairid={id}
                data-updatedstatus={repairsStatuses.READY_FOR_REPAIR}
              >
                <FontAwesomeIcon icon={faCircleCheck} /> Zakończ diagnozę
              </Button>
            )}
            {status === repairsStatuses.READY_FOR_REPAIR && (
              <Button
                color="success"
                size="sm"
                onClick={handleDiagnoseOrStartRepair}
                data-repairid={id}
                data-updatedstatus={repairsStatuses.IN_PROGRESS}
              >
                <FontAwesomeIcon icon={faWrench} /> Rozpocznij naprawę
              </Button>
            )}

            {status === repairsStatuses.IN_PROGRESS && (
              <>
                <Button
                  color="primary"
                  size="sm"
                  onClick={handleRepairUpdateCallback}
                  data-repairid={id}
                  data-updatedstatus={repairsStatuses.DONE}
                >
                  <FontAwesomeIcon icon={faWrench} /> Zakończ
                </Button>{" "}
                <Button
                  color="warning"
                  size="sm"
                  onClick={handleRepairUpdateCallback}
                  data-repairid={id}
                  data-updatedstatus={repairsStatuses.UTILIZATION}
                >
                  <FontAwesomeIcon icon={faTrashAlt} /> Utylizuj
                </Button>
              </>
            )}
            {(status === repairsStatuses.DONE || status === repairsStatuses.WAITING_FOR_PAYMENT) && (
              <Button
                color="dark"
                size="sm"
                onClick={handleRepairUpdateCallback}
                data-repairid={id}
                data-updatedstatus={repairsStatuses.SENT}
              >
                <FontAwesomeIcon icon={faPaperPlane} /> Wyślij
              </Button>
            )}
          </td>
        </tr>
      )
    },
    [handleRepairEditCallback, handleRepairUpdateCallback, handleDiagnoseOrStartRepair, dropdownList]
  )

  const prepareRepairs = useCallback(
    data => {
      // calculateRepairStats(data)

      return data
        .filter(row => {
          if (showDoneRepairs) {
            return row
          } else {
            if (row.status !== repairsStatuses.SENT && row.status !== repairsStatuses.UTILIZATION) {
              return row
            }
          }
          return null
        })
        .map(mapRepairToAction)
    },
    [mapRepairToAction, showDoneRepairs]
  )

  const fetchRepairs = useCallback(async () => {
    const dispatchRepairs = repairs => dispatch(d(ActionTypes.SET_REPAIRSLIST, repairs))
    await API.fetchRepairs(dispatchRepairs)
  }, [dispatch])

  useEffect(() => {
    const { repairs } = state

    if (repairs) {
      const items = prepareRepairs(repairs)
      setRepairsList(items)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.repairs, showDoneRepairs])

  useEffect(() => {
    fetchRepairs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <RepairStats repairs={state.repairs} />
        <Button className="float-right" onClick={toggleDoneCallback} color="link">
          {showDoneRepairs ? "Ukryj" : "Pokaż"} Wysłane/Utyl.
        </Button>
      </div>
      <Table bordered hover striped>
        <thead>
          <tr>
            <th style={{ width: "10%" }}>Numer naprawy</th>
            <th /*style={{ width: "10%" }}*/>Numer seryjny</th>
            <th>Czas naprawy</th>
            <th>Status</th>
            <th>Serwisant</th>
            <th>Akcje</th>
          </tr>
        </thead>
        <tbody>{repairsList}</tbody>
      </Table>
    </>
  )
}

/**/

RepairsList.propTypes = {
  startRepairCallback: PropTypes.func,
  finishRepairCallback: PropTypes.func,
  onFetchCallback: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired
}

export default RepairsList
