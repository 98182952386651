import { calculateSerialCheckDigit } from "utils/serialNumberParser"

export const defaultDateShortFormat = "DD/MM/YY"
export const repairsCreatedAtDateFormat = "dddd DD/MM/YY HH:mm"
export const createdShortDateFormat = "DD/MM/YY HH:mm"
export const repairsStatuses = {
  NEW: "NEW",
  DIAGNOSIS: "DIAGNOSIS",
  // WAITING_FOR_APPROVAL: "WAITING_FOR_APPROVAL",
  // APPROVED: "APPROVED",
  // NOT_APPROVED: "NOT_APPROVED",
  // RETURNED_TO_CLIENT: "RETURNED_TO_CLIENT",
  READY_FOR_REPAIR: "READY_FOR_REPAIR",
  IN_PROGRESS: "IN_PROGRESS",
  DONE: "DONE",
  // WAITING_FOR_PAYMENT: "WAITING_FOR_PAYMENT",
  SENT: "SENT",
  UTILIZATION: "UTILIZATION"
}
export const repairsStatusesName = {
  NEW: "Oczekująca",
  DIAGNOSIS: "W trakcie diagnozy",
  WAITING_FOR_APPROVAL: "Oczekuje na akceptację",
  APPROVED: "Zatwierdzona",
  NOT_APPROVED: "Nie zatwierdzona",
  RETURNED_TO_CLIENT: "Zwrócona do klienta",
  READY_FOR_REPAIR: "Gotowy do naprawy",
  IN_PROGRESS: "W trakcie naprawy",
  DONE: "Zakończona",
  // WAITING_FOR_PAYMENT: "Oczekuje na płatność",
  SENT: "Wysłano",
  UTILIZATION: "Utylizacja"
}

export const repairPersonel = {
  PERSON1: "Piotr",
  PERSON2: "Pan Michał",
  PERSON3: "Michał",
  PERSON4: "Jacek"
}

export const mapStatusToName = status => repairsStatusesName[status]
export const mapStatusToColor = status => {
  switch (status) {
    case repairsStatuses.NEW:
      return "info"
    case repairsStatuses.DIAGNOSIS:
      return "diagnosis"
    case repairsStatuses.READY_FOR_REPAIR:
      return "ready-for-repair"
    case repairsStatuses.IN_PROGRESS:
      return "warning"
    case repairsStatuses.DONE:
      return "secondary"
    // case repairsStatuses.WAITING_FOR_PAYMENT:
    //   return "danger"
    case repairsStatuses.UTILIZATION:
      return "trash"
    default:
      return "light"
  }
}

export const validateSerialNumber = num => {
  if (num.length !== 12) {
    return -1
  }

  return `${num}${calculateSerialCheckDigit(num)}`
}
