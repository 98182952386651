export const servicePartsHistoryFormat = "DD/MM (HH:mm)"
export const servicePartsHistoryFormatForMerge = "DDMMHHmm"
export const servicePartsPeopleBarcodes = [
  {
    name: "Piotrek",
    barcode: "PERSON1"
  },
  {
    name: "Roman",
    barcode: "PERSON2"
  },
  {
    name: "Michał",
    barcode: "PERSON3"
  },
  {
    name: "Jacek",
    barcode: "PERSON4"
  },
  {
    name: "Uszkodzony",
    barcode: "PERSON-BROKEN"
  },
  {
    name: "Biuro",
    barcode: "PERSON-OFFICE"
  },
  {
    name: "Sprzedaż",
    barcode: "PERSON-SALES"
  },
  {
    name: "Dostawa",
    barcode: "PERSON-SHIPMENT"
  }
]

export const servicePartsBarcodeConfirmAction = "SAVE"
