import React, { useState } from "react"
// import "./Tooltip.css" // Optional for external styles

const Tooltip = ({ text, children }) => {
  const [hovered, setHovered] = useState(false)

  const showTooltip = () => setHovered(true)
  const hideTooltip = () => setHovered(false)

  return (
    <div
      className="tooltip-container"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      style={{ display: "inline-block", position: "relative" }}
    >
      {children}
      {hovered && (
        <div className="tooltip-box" style={tooltipStyles}>
          {text}
          <div className="tooltip-arrow" style={arrowStyles}></div>
        </div>
      )}
    </div>
  )
}

const tooltipStyles = {
  position: "absolute",
  top: "100%", // Positioning below the element
  left: "50%",
  transform: "translateX(-50%)",
  backgroundColor: "#333",
  color: "#fff",
  padding: "5px 10px",
  borderRadius: "4px",
  whiteSpace: "nowrap",
  zIndex: 1000,
  marginTop: "10px" // Space between the element and tooltip
}

const arrowStyles = {
  position: "absolute",
  top: "-5px", // Arrow placed above the tooltip box
  left: "50%",
  transform: "translateX(-50%)",
  width: "0",
  height: "0",
  borderLeft: "5px solid transparent",
  borderRight: "5px solid transparent",
  borderBottom: "5px solid #333" // Arrow pointing up
}

export default Tooltip
