import { useState, useRef } from "react"
import { Button, InputGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons"

import NavContainer from "components/NavContainer"
import RepairsModal from "components/RepairsModal"
import RepairsList from "components/RepairsList"
import RepairInfoModal from "components/RepairInfoModal"
import RepairPersonel from "components/RepairPersonel"
// import { ActionTypes } from "context/scannerProvider"
// import { d } from "utils/dispatchHelper"

import useScannerContext from "hooks/useScannerContext"

import "./styles.scss"
import API from "API"

const ServiceParts = () => {
  const [state /*, dispatch*/] = useScannerContext()
  const [isNewRepairOpen, setIsNewRepairOpen] = useState(false)
  const [editRepairId, setEditRepairId] = useState(null)
  const searchRepairInputRef = useRef()

  const [searchRepairData, setSearchRepairData] = useState(null)

  const toggle = (force = false) => setIsNewRepairOpen(force === true ? true : !isNewRepairOpen)
  const handleNewPartModalCallback = () => setIsNewRepairOpen(true)
  // const handleOnModalCloseCallback = () => dispatch(d(ActionTypes.REFRESH_REPAIRS_DATA))

  const handleRepairSearch = async () => {
    const singleRepair = await API.fetchRepairByRepairId2(searchRepairInputRef.current?.value)
    setSearchRepairData(singleRepair)
  }

  const handleEditModalClose = () => {
    // console.log("setting modal repair id to null", editRepairId)
    setEditRepairId(null)
  }

  const Navigation = () => {
    return (
      <NavContainer className="repairsNavContainer">
        <Button color="success" onClick={handleNewPartModalCallback}>
          <FontAwesomeIcon icon={faPlus} /> Dodaj naprawę
        </Button>
        <div className="repairs-search">
          <InputGroup>
            <Input placeholder="Szukaj naprawy" innerRef={searchRepairInputRef} />
            <Button color="secondary" onClick={handleRepairSearch}>
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup>
        </div>
      </NavContainer>
    )
  }

  return (
    <div className="content-section repairs-container">
      <h3>Naprawy</h3>
      <Navigation />
      <div>
        <div>
          <RepairsList onFetchCallback={state.shouldFetchRepairs} setEditRepairId={setEditRepairId} />
        </div>
      </div>
      <RepairsModal
        repairId={editRepairId}
        isOpen={isNewRepairOpen}
        toggle={toggle} /* onSave={handleOnModalCloseCallback}*/
        closeModalCallback={handleEditModalClose}
      />
      <RepairInfoModal repairData={searchRepairData} />
      <RepairPersonel />
    </div>
  )
}

export default ServiceParts
