import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Card, CardTitle } from "reactstrap"
import { repairPersonel, repairsStatuses } from "constants/repairs"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser, faStethoscope, faWrench } from "@fortawesome/free-solid-svg-icons"

import useScannerContext from "hooks/useScannerContext"
import API from "API"
import { ActionTypes } from "context/scannerProvider"

const RepairPersonel = () => {
  const [state, dispatch] = useScannerContext()
  const { isOpen, repairData } = state.repairPersonelState
  const isDiagnostic = repairData?.nextState === "DIAGNOSIS"

  const toggle = () => {
    dispatch({ type: ActionTypes.TOGGLE_REPAIR_PERSONEL_MODAL })
  }

  const handlePersonSelection = async person => {
    const repairId = state.repairPersonelState.repairData?.id

    if (repairId) {
      // Update the repair status to IN_PROGRESS with the selected person
      await API.updateRepair(
        repairId,
        state.repairPersonelState.repairData?.nextState || repairsStatuses.IN_PROGRESS,
        person
      )

      // Optionally, close the modal after updating
      toggle()

      // Refresh the repairs list or perform any other necessary actions
      dispatch({ type: ActionTypes.REFRESH_REPAIRS_DATA })
    }
  }

  return (
    <Modal size="lg" centered={true} isOpen={isOpen} toggle={toggle} fade={false}>
      <ModalHeader toggle={toggle}>
        {isDiagnostic
          ? `Wybierz serwisanta do diagnozy R${repairData?.id}`
          : `Wybierz serwisanta naprawy R${repairData?.id}`}
      </ModalHeader>
      <ModalBody>
        <Row>
          {Object.keys(repairPersonel).map((person, index) => (
            <Col sm="3" key={index}>
              <Card body>
                <center>
                  <FontAwesomeIcon icon={faUser} size="2x" />
                  <h5>{repairPersonel[person]}</h5>
                </center>
                <Button color={isDiagnostic ? "diagnosis" : "success"} onClick={() => handlePersonSelection(person)}>
                  {isDiagnostic ? (
                    <>
                      <FontAwesomeIcon icon={faStethoscope} /> Diagnoza
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faWrench} /> Rozpocznij
                    </>
                  )}
                </Button>
              </Card>
            </Col>
          ))}
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default RepairPersonel
