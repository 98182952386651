import { createContext, useReducer } from "react"

export const ActionTypes = {
  SCAN_PART: "SCAN_PART",
  SCANNER_OPEN: "SCANNER_OPEN",
  RESET: "RESET",
  SCANNER_WINDOW_TOGGLE: "SCANNER_WINDOW_TOGGLE",
  REFRESH_DATA: "REFRESH_DATA",
  REFRESH_REPAIRS_DATA: "REFRESH_REPAIRS_DATA",
  SAVE_AND_REFRESH: "SAVE_AND_REFRESH",
  TOGGLE_SCANNING: "TOGGLE_SCANNING",
  SET_REPAIRSLIST: "SET_REPAIRSLIST",
  SET_SERVICE_PARTS: "SET_SERVICE_PARTS",
  SET_SERVICE_PARTS_HISTORY: "SET_SERVICE_PARTS_HISTORY",
  TOGGLE_REPAIR_PERSONEL_MODAL: "TOGGLE_REPAIR_PERSONEL_MODAL"
}

const initialState = {
  isScanning: false,
  shouldFetch: true,
  shouldFetchRepairs: true,
  isScanOpen: false,
  repairPersonelState: {
    isOpen: false,
    repairData: null, // holds the repair data when the modal is open
    nextStatus: null
  },
  scannedPartCount: 0,
  partName: "",
  partCode: "",
  partCount: 0,
  serviceParts: [],
  partsHistory: [],
  repairs: []
}

const reducer = (state = initialState, action) => {
  const { type, payload = null } = action

  switch (type) {
    case ActionTypes.SET_SERVICE_PARTS:
      return {
        ...state,
        serviceParts: payload
      }

    case ActionTypes.SET_SERVICE_PARTS_HISTORY:
      return {
        ...state,
        partsHistory: payload
      }

    case ActionTypes.SCANNER_OPEN:
      return {
        ...state,
        isScanOpen: true,
        partName: payload.partName,
        partCode: payload.partCode,
        partCount: payload.partCount
      }

    case ActionTypes.SCAN_PART:
      return {
        ...state,
        isScanOpen: true,
        scannedPartCount: payload === null ? state.scannedPartCount + 1 : payload
      }

    case ActionTypes.REFRESH_DATA:
      return {
        ...state
        // shouldFetch: !state.shouldFetch
      }

    case ActionTypes.REFRESH_REPAIRS_DATA:
      return {
        ...state,
        shouldFetchRepairs: !state.shouldFetchRepairs
      }

    case ActionTypes.SET_REPAIRSLIST:
      return {
        ...state,
        repairs: payload
      }

    case ActionTypes.SAVE_AND_REFRESH:
      return {
        ...state,
        isScanOpen: false,
        // shouldFetch: !state.shouldFetch,
        scannedPartCount: 0
      }

    case ActionTypes.SCANNER_WINDOW_TOGGLE:
      return {
        ...state,
        isScanOpen: false,
        scannedPartCount: 0
      }

    case ActionTypes.TOGGLE_SCANNING:
      return {
        ...state,
        isScanning: !state.isScanning
      }

    case ActionTypes.TOGGLE_REPAIR_PERSONEL_MODAL:
      return {
        ...state,
        repairPersonelState: {
          isOpen: !state.repairPersonelState.isOpen, // toggle the modal
          repairData: action.payload || null, // set repair data when opening the modal
          nextStatus: null
        }
      }

    case ActionTypes.RESET:
      return initialState

    default:
      return state
  }
}

export const ScannerContext = createContext()

export const ScannerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <ScannerContext.Provider value={[state, dispatch]}>{children}</ScannerContext.Provider>
}
